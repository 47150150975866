// var $ = require('jquery');
// window.jQuery = $;
// window.$ = $;
var w, h;
w = window.innerWidth;
h = window.innerHeight;
var waypoint = require('/node_modules/waypoints/lib/noframework.waypoints.js');

import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import Headroom from "headroom.js";

//Flickity
var Flickity = require('flickity');
require('flickity-imagesloaded');
require('flickity-fullscreen');

import Swiper from 'swiper/bundle';
//import Swiper from 'swiper';
//* import the Swiper styles
import 'swiper/css';

 
// // import styles bundle
import 'swiper/css/bundle';

const swiper = new Swiper('.swiper', {
  // Optional parameters
  //direction: 'vertical',
  loop: true,
  autoHeight: true,
  lazy: true,
  touchRatio:.8,
  //allowTouchMove:false,
  lazyPreloadPrevNext:2,
  //cssMode: true,
  slidesPerView: "auto",
  //slidesPerView: 1.3,
  centeredSlides: true,
  spaceBetween: 0,
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
    dynamicMainBullets: 3
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true
  },
});

/* gallery for wp */
const swiperWp = new Swiper('.swiper-wp', {
  //wrapperClass: 'wp-block-gallery',
  //slideClass: 'wp-block-image',
  loop: true,
  autoHeight: true,
  //lazy: true,
  //lazyPreloadPrevNext: 2,
  //cssMode: true,
  slidesPerView: "auto",
  //slidesPerView: 1.3,
  centeredSlides: true,
  spaceBetween: 0,
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
    dynamicMainBullets: 3
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  // And if we need scrollbar
  scrollbar: {
    el: '.swiper-scrollbar',
    draggable: true
  },
});



var ani = document.querySelectorAll('.d-ani');
ani.forEach(element => {
  var waypoint = new Waypoint({
    element: element,
    handler: function (direction) {
      //console.log(direction + ' :Scrolled to waypoint!')
      element.classList.add('do-it');
    },
    offset:'85%'
  })
});




function elementExists(ele){
  if (typeof(ele) != 'undefined' && ele != null){
    return true;
  }
}




const observer = new IntersectionObserver(
  ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
  { threshold: [1] }
);


const el = document.querySelector(".sticky-content");
if (elementExists(el)) {
  observer.observe(el);
}
const el1 = document.querySelector(".sticky-feature-1");
if (elementExists(el1)) {
  observer.observe(el1);
}





const hektorAlert ={
  dataId : null,
  alertElement : document.getElementById('alert'),
  body_ : document.querySelector('body'),
  check_cookies:function(){
    //console.log('does check!');
    if (elementExists(this.alertElement)){
        this.dataId = this.alertElement.getAttribute('data-id');
    }
      
    if(this.dataId!=null || this.dataId!=undefined){
      this.alertElement.classList.add("hide");
      if(document.cookie){
        //console.log(document.cookie);
        const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith('hide_alert_id_'))
        ?.split('hide_alert_id_')[1];
        if(cookieValue!=null){
          var a_id = cookieValue.split('=')[0];
          var a_bool = cookieValue.split('=')[1];
          //console.log(a_id);
          if(a_bool == 'true' && a_id!==''){
              var dataEl = document.querySelector('[data-id="'+a_id+'"]');
              
              
              if(elementExists(dataEl)){
                dataEl.classList.add("d-none");
                this.body_.classList.remove("hektor-alert");
              }
              
          }
        }
      }
    }
  },
  construct:function(){
    window.addEventListener('DOMContentLoaded', (event) => {
      hektorAlert.check_cookies();
      const myAlert = document.getElementById('alert');
      if(elementExists(myAlert)){
        var alertId = myAlert.getAttribute('data-id');
        myAlert.addEventListener('closed.bs.alert', event => {
          document.cookie = "hide_alert_id_"+alertId+"=true;SameSite=None; Secure";
          this.body_.classList.remove("hektor-alert");
        })
      }
    });
  }
}
hektorAlert.construct();

// jQuery.event.special.touchstart = {
//   setup: function( _, ns, handle ) {
//       this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
//   }
// };
// jQuery.event.special.touchmove = {
//   setup: function( _, ns, handle ) {
//       this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
//   }
// };

//$=jQuery.noConflict();


//var _body = $('body');
var root = document.documentElement;

document.addEventListener('mousemove', function(evt){
  // console.log(evt.clientX)
  var st = window.scrollY;
  var x = evt.clientX ;
  var y = evt.clientY ;
  
  //console.log(w); 
  root.style.setProperty('--scroll-y', st);
  root.style.setProperty('--mouse-x', x)
  root.style.setProperty('--mouse-y', y);
  root.style.setProperty('--window-w', w);
  root.style.setProperty('--window-h', h);
  root.style.setProperty('--mouse-x-px', x+'px');
  root.style.setProperty('--mouse-y-px', y+'px');
  root.style.setProperty('--mouse-y-abs-px', (y+st)+'px');
  
  var half = w / 2;
  if(x>half){
    root.style.setProperty('--right-part-screen','block');
    root.style.setProperty('--left-part-screen','none');
  }else{
    root.style.setProperty('--left-part-screen', 'block');
    root.style.setProperty('--right-part-screen','none');
  }

});

window.addEventListener('resize', function(evt){
  w = window.innerWidth;
  h = window.innerHeight;
});


function mobileMenu(ele) {
  
  w = window.innerWidth;
  // console.log(w);
  if (w < 620) {
    //console.log(ele);
    var menu = document.getElementById('main-menu-id');
    var back = document.querySelector('.main-nav-v3 .back');
    //console.log(back);
    back.addEventListener('click', function(){ 
      menu.classList.remove('level1');
      back.classList.remove('show');
    });
    var mi = document.querySelectorAll('.menu-item-has-children');
    for (var i = 0; i < mi.length; i++) {
      //console.log('i:'+i);
      // mi[i].classList.remove('hover');
      ele.classList.add('hover','active');
      
      menu.classList.add('level1');
      back.classList.add('show');
    }
   
  }
}
function removeAllclasses(ele,cName) {
  var allClasses = document.querySelectorAll(ele);
  for (var i = 0; i < allClasses.length; i++) {
    allClasses[i].classList.remove(cName);
  }
}

var videoPlayer = {
  innit: function (vidId) {
    ///this.addModal(vidId);
    ///this.videoModal = document.querySelector('.video-modal');
    //$(".video-container").fitVids();
    // $('.video-modal .btn-close').click(function (e) {
    //   videoPlayer.hideModal();
    // });
    //var btn_close_m = document.querySelector('.btn-close-m');
    // btn_close_m.addEventListener('click',function () {
    //   videoPlayer.hideModal();
    // });

  },
  addModal: function (vidId) {
    //alert(vidId);
    var output = '';
    document.body.innerHTML+=output;
    var st = setTimeout(function () {
      //var videoModal = document.querySelector('.video-modal');
      videoPlayer.videoModal.classList.remove('hide');
      //$('.video-modal').removeClass('hide');
    }, 50);
  },
  hideModal: function () {
    //$('.video-modal').addClass('hide');
    //videoPlayer.videoModal.classList.add('hide');
    var st = setTimeout(function () {
      var elem = document.querySelector('.video-modal');
      elem.parentNode.removeChild(elem);
      alert('!!-!!');
     
    }, 50);
  }
}
// jQuery(function () {
//   alert('Yolo!');
// });
var w = window.innerWidth;
function uiInnit() {
  
  const myModal = document.getElementById('hektorModal');
  //const myInput = document.getElementById('myInput')

  //const myModal = document.getElementById('myModal')
  myModal.addEventListener('shown.bs.modal', (e) => {
    //myInput.focus()
    //alert('opening up!');
    // console.log(e);
    // console.log(e.currentTarget);
    // console.log(e.relatedTarget);
    let relatedTarget = e.relatedTarget;
    let dataVideo = relatedTarget.getAttribute('data-video');
    // console.log(dataVideo);
    let videoContainer = document.querySelector('.video-modal-container');
    let videoHtml ='<iframe width="560" height="315" src="https://www.youtube.com/embed/' + dataVideo + '?showinfo=0&modestbranding=1&autoplay=1" frameborder="0" allow="modestbranding; showinfo; accelerometer; autoplay; encrypted-media; gyroscope; off-picture-in-picture" allowfullscreen></iframe>';
    videoContainer.innerHTML=videoHtml;

    //videoId = document.querySelector('');
  })

  myModal.addEventListener('hide.bs.modal', (e) => {
    //myInput.focus()
    let videoContainer = document.querySelector('.video-modal-container');
    let videoHtml = '';
    videoContainer.innerHTML = videoHtml;

    //videoId = document.querySelector('');
  })


  swiper.on('resize', function () {
    var st1 = setTimeout(function (e) {
      swiper.update();
    },1500);
  });

  //click outside
  var specifiedElement = document.querySelector('.main-nav-v3');
  var specifiedElement1 = document.querySelector('.hamburger');

  //I'm using "click" but it works with any event
  document.addEventListener('click', function (event) {
    var isClickInside = specifiedElement.contains(event.target);
    var isClickInside1 = specifiedElement1.contains(event.target);
    if (!isClickInside && !isClickInside1) {
      //only do stuff when menu is open
      if (document.body.classList.contains('menu-open')) {
        //console.log('You clicked out A');
        document.body.classList.remove('menu-open');
        removeAllclasses('#main-menu-id .menu-item-has-children', 'hover');
        
      }
      
    } 
  });
  var CheckF = document.querySelector('.default-carousel');
  var CheckF1 = document.querySelector('.default-carousel-1');
  if (CheckF != null) {
    var flkty = new Flickity('.default-carousel');
  }
  if (CheckF1 != null) {
    var flkty = new Flickity('.default-carousel-1');
  }

  // Video modal  stuff
  var btn_video = document.querySelector('.btn-video');
  if (btn_video != null) {
    btn_video.addEventListener('click', function (event) {
      console.log('click' + event);
      var vidId = event.target.getAttribute('data-video');
      console.log('click');
      videoPlayer.innit(vidId);
    });  
  }

  // tabs EQV page
  var tab_eqv_page = document.querySelectorAll('.tab');
  var eqv_model = document.querySelectorAll('.eqv-model');
  var type;
  
  for (var i = 0; i < tab_eqv_page.length; i++) {
    tab_eqv_page[i].addEventListener('click', function (e) {
       
        
      type = e.currentTarget.getAttribute('data-type'); 
      eqv_model[0].classList.add('d-none');
      eqv_model[1].classList.add('d-none');
      removeAllclasses('.tab','tab-active');
      document.querySelector('.tonke-eqv-' + type).classList.remove('d-none');
      document.querySelector('.tab-' + type).classList.add('tab-active');
      //console.log('hierie');
      flkty.resize();
      flkty1.resize();

      // $('.tab').on('click', function () {
      //   var type = $(this).data('type');
      //   $('.eqv-model').addClass('d-none');
      //   $('.tab').removeClass('tab-active');
      //   $('.tonke-eqv-' + type).removeClass('d-none');
      //   $('.tab-' + type).addClass('tab-active');

      // });
        

      
    });
  }
  
  
  // $('.btn-video,.text-link-video').click(function () {
  //   var vidId = $(this).data('video');
    
  // });
 if(w>900){
  document.body.addEventListener("mouseenter", function (e) {
    //console.log(e);
    if (e.target.classList.contains("swiper")) {
      //console.log("catched");
      //alert('!!!');
      document.body.classList.add("show-car-nav");
      //console.log(e);
      //alert('yolo!');
    }
  }, true); // capturing phase
  document.body.addEventListener("mouseleave", function (e) {
    if (e.target.classList.contains("swiper")) {
      //console.log("catchedleaved");
      document.body.classList.remove("show-car-nav");
    }
  }, true); // capturing phase
 }
  var carousel = document.querySelector('.swiper');
  if (carousel != null) {
    if (w > 900) {
      carousel.addEventListener('click', function (event) {
        // console.log(event); // The event details
        // console.log(event.target); // The clicked element

        var half = w / 2;
        var x = root.style.getPropertyValue('--mouse-x');
        if (x > half) {


          swiper.slideNext();

        } else {

          swiper.slidePrev();

        }

      });
    }
  }

  //hamburger menu open and close
  var hamburger_menu = document.querySelector('.hamburger');
  var menu = document.querySelector('#main-menu-id');

  var menu_sub = menu.querySelectorAll('.menu-item-has-children');
  hamburger_menu.addEventListener("click", function () {
    document.body.classList.toggle('menu-open');
    if (menu.classList.contains('level1')) {
      console.log('has');
      
    }
    var st = setTimeout(function () {
      menu.classList.remove('level1');
    }, 500);
    
    removeAllclasses('#main-menu-id .menu-item-has-children','hover');
    
  });
  //menu hover
  
 // console.log(menu_sub);
  for (var i = 0; i < menu_sub.length; i++) {
    
    menu_sub[i].addEventListener('click', function (event) {
      //alert('click!');
      
      //removeAllclasses('.menu-item-has-children', 'hover');
      //console.log(event.currentTarget.querySelector('.menu-item-has-children > a'));
      var preventEle = event.currentTarget.querySelector('.menu-item-has-children > a');
      
      event.currentTarget.classList.remove('hover');
      event.currentTarget.classList.add('hover');
      
      mobileMenu(event.target);
      if (event.target === preventEle) {
        event.preventDefault();
      }
      
      
    });
   
    menu_sub[i].addEventListener('touchstart', function (event) {
      //mobileMenu(event.target);
    });
  }

  
  

  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
}

document.addEventListener("DOMContentLoaded", uiInnit);





/* Headroom */

 var myElement = document.querySelector(".tonke-v3-meta-menu-wr");
  // construct an instance of Headroom, passing the element
  var options = {
    // vertical offset in px before element is first unpinned
    offset : 20,
    // scroll tolerance in px before state changes
    tolerance : 0,
    // or you can specify tolerance individually for up/down scroll
    tolerance : {
        up : 5,
        down : 0
    }
  };
  var headroom  = new Headroom(myElement,options);
  // initialise
  headroom.init();


  
  
  var readmore = document.querySelector('.read-more');
  if(readmore!=null){
    var text_open = readmore.getAttribute('data-text-open');
    var text_closed = readmore.getAttribute('data-text-closed');
    readmore.addEventListener('click', function () {
      if (readmore.classList.contains('open')){
        readmore.classList.remove('open');
        readmore.parentNode.nextElementSibling.classList.add('d-none');
        readmore.innerHTML = text_closed;
      } else {
        readmore.parentNode.nextElementSibling.classList.remove('d-none');
  
        readmore.classList.add('open');
        readmore.innerHTML = text_open; 
      }
    });
  }



